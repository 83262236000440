/* Main CSS */

/* img dimensions */

.small-image {
    width: 40px;
    height: auto;
  }
  
/* Global Background Image */

body {
  background-image: url('/public/snowflake_burst_white.png');
  background-size: cover; /* Cover the entire viewport */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-attachment: fixed; /* Optional: Fix the background */
}

/* AdminUpdatePatch Purchase New PatchCard */

.narrow-button {
  width: auto; /* or any specific width */
  white-space: nowrap; /* To prevent text wrapping */
  color: rgb(252, 252, 252);
}

/* Font for h2 */
h1, h2 {
  font-family: 'Montserrat', sans-serif;
}

